@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.logo-p{
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
}
.logo1{
    font-weight: 700;
}

@media screen and (max-width: 600px){
    .logo-p{
        font-size: 16px;
    }
}