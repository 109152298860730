.associate-right {
	display: grid;
	grid-template-columns: 33.33% 33.33% 33.33%;
	max-width: 30rem;
	max-height: 30rem;
}
.grid-item {
	padding: 5px;
	text-align: center;
}

@media screen and (max-width: 991px) {
	.associate-right {
		margin-top: -120px;
	}
}

.box {
	background-color: #e5e5e5;
	height: 12.5rem;
	width: 12.5rem;
	position: relative;
}

@media screen and (max-width: 991px) {
	.box {
		background-color: rgba(255, 255, 255, 0);
		height: 12.5rem;
		width: 12.5rem;
		position: relative;
	}
}
