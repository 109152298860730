#hero-main {
	width: 100%;
	min-height: 100vh;
	/* max-height: 800px; */
	background-image: url("../../assets/BackgroundImg1.jpeg");
	/* background-size: 100vh 800px; */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	/* background: linear-gradient(rgba(0, 0, 0, 0.548), rgba(0, 0, 0, 0.411)), url('../../assets/img_hero_1.png'); */
}

@media screen and (max-width: 768px) {
	#hero-main {
		width: 100%;
		min-height: 100vh;
		/* max-height: 800px; */
		background-image: url("../../assets/BackgroundImg2.jpg");
		/* background-size: 100vh 800px; */
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		/* background: linear-gradient(rgba(0, 0, 0, 0.548), rgba(0, 0, 0, 0.411)), url('../../assets/img_hero_1.png'); */
	}
}

.text-blue {
	color: #1f26d2;
}

.hero-left {
	margin-top: 100px;
}
@media screen and (max-width: 600px) {
	.hero-left {
		margin-top: 40px;
	}
}
.sq-img {
	position: relative;
}
@media screen and (max-width: 768px) {
	.sq-img {
		display: none;
	}
}
.hero-heading {
	position: relative;
	top: -165px;
	left: 70px;
}
@media screen and (max-width: 768px) {
	.hero-heading {
		position: static;
	}
}
.hero-content {
	margin-top: -80px;
}
@media screen and (max-width: 768px) {
	.hero-content {
		margin-top: 0px;
	}
}
.heroCtaBtn {
	width: 120px;
	height: 40px;
	border-radius: 5px;
}
.hero-section-2 {
	background: rgba(255, 255, 255, 0.7);
	backdrop-filter: blur(6px);
	border-radius: 29px;
	/* height: 71.8vh;  */
	max-width: 54vh;
}
.circle-with-line hr {
	border-top: 3px solid #000;
}
.outer-circle {
	width: 24px;
	height: 24px;
	border: 1px solid #000;
	border-radius: 50%;
}
.inner-circle {
	width: 16px;
	height: 16px;
	background-color: #1f26d2;
	border-radius: 50%;
	margin-top: 3px;
}
.inner-circle1 {
	width: 16px;
	height: 16px;
	background-color: red;
	border-radius: 50%;
	margin-top: 3px;
}
.exp-btn {
	background-color: red;
	color: white;
	border-color: red;
}
.exp-btn:hover {
	background-color: #ab0000;
	color: white;
	border-color: #ab0000;
}
.exp-btn:active {
	background-color: #ab0000;
	color: white;
	border-color: #ab0000;
}
/* .feature-1{
    margin-top: 2.5rem;
} */
