.masonry{
    columns: 3 250px;
    gap: 15px;
    position: relative;
}
.masonry img{
    width: 100%;
    margin-bottom: 15px;
}
.img-card{
    position: relative;
    color: white;
}
.img-card:hover{
    opacity: .8;
    transition:all 0.25s ease;
    cursor: pointer;
}
.overlay{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 58.12%);
    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.507168) 25.83%, rgba(0, 0, 0, 0.89) 61.77%); */
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
}